// var $ = require('jQuery');
global.jQuery     = require('./Vendor/jquery-3.3.1.min.js');
var debounce   = require('js-throttle-debounce');
var initIt       = require('initIt');
var flickIt        = require('flickIt');
// var Isotope        = require('isotope-layout');
var packery        = require('packery');
// var imagesLoaded     = require('imagesloaded');
var imagesLoaded     = require('./Vendor/imagesloaded.js');
var niceSelect     = require('./Vendor/jquery.nice-select.min.js');

// var mapIt        = require('mapIt');

// var Waypoint        = require('waypoints');
// import { Waypoint } from 'waypoints';
// import { Waypoint } from './Vendor/jquery.waypoints.min.js';
var Waypoint        = require('./Vendor/jquery.waypoints.min.js');

// var cookIt        = require('cookIt');
// var Isotope    = require('isotope-layout');
// var niceSelect = require('./Vendor/jquery.nice-select.min.js');
var WOW        = require('./Vendor/wow.min.js');
// var CountUp        = require('./Vendor/countUp.min.js');

import { CountUp } from './Vendor/countUp.min.js';
import { Loader } from "@googlemaps/js-api-loader"

(function($) {
$(document).ready(function() {

$('html').addClass('is_fading');

/*===========================
=            wow            =
===========================*/

new WOW({offset: 20}).init();

/*==================================
=            googlemaps            =
==================================*/

// let map;

// function initMap() {
// 	map = new google.maps.Map(document.getElementById("gmaps"), {
// 	center: { lat: 48.196690, lng: 16.342660 },
// 	zoom: 15,
// 	});
// 	console.log('google alert go');
// }

if ($('#gmaps').length) {



var stylers = [{
    "stylers": [
      {
        "saturation": -80
      },
      {
        "lightness": 30
      }
    ]
  },
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#bf1212"
      },
      {
        "saturation": -80
      }
    ]
  },
  {
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#212121"
      }
    ]
  },
  {
    "elementType": "labels",
    "stylers": [
      {
        "color": "#3d433a"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "color": "#3d433a"
      },
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#212121"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#181818"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#3d433a"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1b1b1b"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#2c2c2c"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#8a8a8a"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#373737"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#373737"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#373737"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#3c3c3c"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#3d433a"
      }
    ]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#4e4e4e"
      }
    ]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#3c3c3c"
      }
    ]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#3c3c3c"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#3c3c3c"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#3c3c3c"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#000000"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#3d433a"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#3d3d3d"
      }
    ]
  }
];


const loader = new Loader({
	apiKey: "AIzaSyCaOgrukUNslu7RlsUsnR7Itn1PxCZsQnQ",
	version: "weekly",
});
loader.load().then(() => {

	const myLatLng = { lat: 48.196690, lng: 16.342660 };

	var $urlPrefix = $('html').attr('data-assets-url');

    var imageUrl = $urlPrefix + '/Svg/gmaps-button.png'

    var icon = {
    	url : imageUrl,
    	scaledSize: new google.maps.Size(46, 61)
    }

	// var image = "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQxFoh469eOsZQkuPOLpZn3R6yyIExkZCxOxf4ywfeY3v330EwP3Q";

	var map = new google.maps.Map(document.getElementById("gmaps"), {
		center: myLatLng,
		zoom: 15,
		styles: stylers
	});
	var marker = new google.maps.Marker({
	        position: myLatLng,
	        map,
	        url: 'https://www.google.at/maps/place/Brandl+%26+Talos+Rechtsanw%C3%A4lte+GmbH/@48.1966716,16.3404594,17z/data=!3m1!4b1!4m5!3m4!1s0x476d07f52aeffa3f:0x6b37934f68cb4cf1!8m2!3d48.196668!4d16.3426481',
	        title: "Brandl Talos",
	        icon: icon
	});

	google.maps.event.addListener(marker, 'click', function() {
		// window.location.href = marker.url;
		window.open(marker.url, '_blank');
    });
});
}

/*================================
=            count up            =
================================*/

// if ($('.section-Numbers').length) {
	// $('.section-Numbers .number-item .number-text').each(function(index, el) {
	// 	console.log(el.className);

	// 	const options = {
	// 		duration: 6,
	// 	};


	// 	var numberCountup = new CountUp(el, el.innerHTML, options);
	// 	if (!numberCountup.error) {
	// 		numberCountup.start();
	// 	} else {
	// 		console.error(numberCountup.error);
	// 	}
	// });
// }

/*================================
=            waypoint            =
================================*/
// console.log(document.querySelectorAll('.section-Numbers'));
// console.log('something');
$('.section-Numbers').waypoint(function(direction) {
	if (direction == 'down') {
		console.log('Scrolled to waypoint down!');
		$('.section-Numbers .number-item .number-text').each(function(index, el) {
			console.log(el.className);
			var start = el.dataset.start
			var end = el.dataset.end
			const options = {
				startVal: start,
				duration: 6,
                separator: '.',
			};

			var numberCountup = new CountUp(el, end, options);
			if (!numberCountup.error) {
				numberCountup.start();
			} else {
				console.error(numberCountup.error);
			}
		});
        numberCountup.destroy()
	} else {
		console.log('Scrolled to waypoint up');

	}

}, { offset: '50%' });
// var waypoint = new Waypoint({
// 	element: document.querySelectorAll('.section-Numbers'),
// 	handler: function(direction) {
// 		console.log('Scrolled to waypoint!')
// 		$('.section-Numbers .number-item .number-text').each(function(index, el) {
// 			console.log(el.className);

// 			const options = {
// 				duration: 6,
// 			};


// 			var numberCountup = new CountUp(el, el.innerHTML, options);
// 			if (!numberCountup.error) {
// 				numberCountup.start();
// 			} else {
// 				console.error(numberCountup.error);
// 			}
// 		});
// 	}
// })


/*===================================
=            global vars            =
===================================*/

var windowWidth = $(window).width();

/*===================================
=            site loader            =
===================================*/

$(document).on('click', 'a:not(.menu-item-has-children > a, a._brlbs-btn)', function(e) {
    e.preventDefault();

    var url = $(this).attr('href');
    var target = $(this).attr('target');

    // console.log(url);
    if (url.includes('mailto:')) {
        window.location.href = url;
    } else if(target == '_blank') {
    	// console.log('target blank');

        // window.location.href = url;
        // window.open = url;
        var win = window.open(url, '_blank');
  		win.focus();
    } else {
	    // $('.loader2').removeClass('is_animated_down');
      $('html').addClass('is_hidden_op');
	    setTimeout(function() {
	        window.location.href = url;
	    }, 700);
    }

	


    // setTimeout(function() { // for error remove again
    // 	$('.loader2').addClass('is_animated_down');
    // }, 5000);


});

/*===============================================
=            open/close publications            =
===============================================*/

$(document).on('click', '.js_togglePub', function() {
    var $this = $(this);
    var $teamDetail = $this.closest('.team-detail-highlights');
    var $showHideWrapper = $teamDetail.find('.show-hide-wrapper');
    var $showHideWrapperInner = $showHideWrapper.find('.show-hide-wrapper-inner');
    var innerHeight = $showHideWrapperInner.innerHeight();

    // if ($this.hasClass('is-active')) {

    //     $showHideWrapper.css('max-height', 0);
    //     $this.removeClass('is-active');
    // } else {

    //     $this.addClass('is-active');
    // }

    $showHideWrapper.css('max-height', innerHeight+100);
    // setTimeout(function() {
    $this.addClass('is-hidden');
    $teamDetail.css('padding-bottom', 0);
    // }, 200);
});




/*===================================
=            scroll down            =
===================================*/

$(document).on('click', '.js_scrolldown', function() {
	$([document.documentElement, document.body]).animate({
        scrollTop: $('.site').offset().top
    }, 300);
});

/*============================
=            menu            =
============================*/

$(document).on('click', '.menu-header-menue-de-container .menu-item.menu-item-has-children > a, .menu-header-menue-en-container .menu-item.menu-item-has-children > a', function(e) {
	e.preventDefault();
	// console.log('click');
	var $this = $(this);
	if ($this.hasClass('is-active')) {
		$('.menu-header-menue-de-container .menu-item.menu-item-has-children a, .menu-header-menue-en-container .menu-item.menu-item-has-children a').removeClass('is-active');
	} else {
		$('.menu-header-menue-de-container .menu-item.menu-item-has-children a, .menu-header-menue-en-container .menu-item.menu-item-has-children a').removeClass('is-active');
		$this.addClass('is-active');
	}
});

$(document).on('click', '.js_openCloseMenu', function() {
	if ($('.nav-main-wrapper').hasClass('is-active')) {
		closeMenu();
	} else {
		openMenu();
	}
});

$(document).on('click', '.js_closeMenu', closeMenu);

function openMenu() {
	$('.nav-main-wrapper').addClass('is-active');
	$('.nav').addClass('is-active');
	$('.nav-open-overlay').addClass('is-active');
	$('body').addClass('is-fixed');
}
function closeMenu() {
	$('.nav-main-wrapper').removeClass('is-active');
	$('.nav').removeClass('is-active');
	$('.nav-open-overlay').removeClass('is-active');
	$('body').removeClass('is-fixed');
}

/*===============================
=            isotope            =
===============================*/

function initIsotope() {
    var grid = document.querySelector('.page-template-Insights .insights');
    var iso = new packery( grid, {
        // options...
        itemSelector: '.insight-item',
        stamp: '.insight-stamp',
        // fit: ('.insight-stamp', 1000, 0),
        percentPosition: true,
        masonry: {
            columnWidth: '.insight-item-sizer'
        }
    });


    var $images = $(iso.element).find('.image-wrapper img');
    // console.log();
    if ($images.length != 0) {
        $images.each(function(index, el) {
            $(el).imagesLoaded().progress( function(instance, image) {
                // console.log('relayout');
                // console.log(instance);
                // console.log(image);
                // $iso.isotope('layout');
                iso.layout();
            });
        });
    }
    return iso;
}

if ($('.page-template-Insights').length) {
	var iso = initIsotope();
}
/*====================================
=            prevent link            =
====================================*/

$(document).on('click', '.js_prevent_link', function(e) {
	e.preventDefault();
})


/*===================================
=            nice select            =
===================================*/


$('.select-filter').niceSelect();

// select handlers

$(document).on('click', '.page-template-team .nice-select li', function () {

	// disable other select start
	var $otherSelect = $(this).closest('.nice-select').siblings('.nice-select');
	var $otherFirstOption = $otherSelect.find('.option').first();
	$otherSelect.find('.selected').removeClass('selected')
	$otherFirstOption.addClass('selected');
	var text = $otherFirstOption.data('display') || $otherFirstOption.text();
	$otherSelect.find('.current').text(text);
	$otherSelect.prev('select').val($otherFirstOption.data('value')).trigger('change');
	// if ($(this).closest('.nice-select').nextAll('.nice-select').length == 1) { // first select
	// } else { // second select
	// }
	// disable other select end


	if ($(this).attr('data-display')) {
		$('.page-template-team .team-wrapper').removeClass('is-hidden');
		$('.page-template-team .team-wrapper .team-item').removeClass('is-hidden');
		$('.page-template-team .team-content .headline').removeClass('is-hidden');
	} else {


		var val = $(this).attr('data-value');
		// console.log(val);

		$('.page-template-team .team-wrapper').addClass('is-hidden');
		$('.page-template-team .team-wrapper .team-item').addClass('is-hidden');
		$('.page-template-team .team-content .headline').addClass('is-hidden');

		$('.team-wrapper .team-item.' + val).removeClass('is-hidden');
		$('.team-wrapper .team-item.' + val).closest('.team-wrapper').removeClass('is-hidden');
		$('.team-wrapper .team-item.' + val).closest('.team-wrapper').prev('.headline').removeClass('is-hidden');
	}


	// $('.page-template-team .team-wrapper .team-item.' + val).removeClass('is-hidden');
	// $('.page-template-team .team-wrapper .team-item.naehrboden').removeClass('is-hidden');
});

$(document).on('click', '.page-template-Insights .nice-select li', function () {

	var val = $(this).attr('data-value');
    var rootUrl = $('html').attr('data-root-url');
	// if ($(this).attr('data-display')) {
	// 	console.log('initial');
	// } else {
		console.log('rest');
		var selected = {};
		$('.nice-select').each(function(index, el) {
			var sVal = $(el).find('.option.selected').attr('data-value');
			var sStand = $(el).find('.option').attr('data-display');
			if (sVal != sStand) {
				selected[index] = sVal;
			} else {
				selected[index] = 'initial';
			}
		});

        $('.insights-overview .insights .insight-item').remove();
        $('.insights-overview .insights .insight-stamp').remove();
        $('.insights-overview .insights .svg-circle').addClass('is-visible');
        $('.insights-overview .insights .empty-msg').removeClass('is-visible');

		$.ajax({
			type: 'POST',
            url: '/wp-admin/admin-ajax.php',
            dataType: 'json',
			data: {
				action: 'filterInsights',
				selection: selected
			},
		})
		.done(function(data) {
			// console.log("success");
            console.log(data);
            // data.insights
            if (data.insights.length) {
                iso.destroy();
                $('.insights-overview .insights .svg-circle').removeClass('is-visible');
                $('.insights-overview .insights').append(data.insights);
                $('.intro-insights .entries .entries-number').html(data.insightsCount);
                initIsotope();
            } else {
                $('.insights-overview .insights .svg-circle').removeClass('is-visible');
                $('.insights-overview .insights .empty-msg').addClass('is-visible');
                $('.insights-overview .insights').css('height', 'auto');
                $('.intro-insights .entries .entries-number').html('0');
            }

            setTimeout(function() {
                // iso.layout();
                // iso.reloadItems();
            }, 500);
		})
		.fail(function(e) {
			console.log(e);
            console.log("error");
		})
		.always(function() {
			console.log("complete");
		});
		
	// }

	// $('.page-template-team .team-wrapper .team-item.' + val).removeClass('is-hidden');
	// $('.page-template-team .team-wrapper .team-item.naehrboden').removeClass('is-hidden');
});

/*===================================
=            repeatOften            =
===================================*/
repeatOften();
function repeatOften() {

    if ($(window).scrollTop() != 0) {
        if (!$('nav').hasClass('is-scrolled')) {
            $('nav').addClass('is-scrolled');
        }
    } else {
        if ($('nav').hasClass('is-scrolled')) {
            $('nav').removeClass('is-scrolled');
        }
    }
}

$(window).on('scroll', function() {
    requestAnimationFrame(repeatOften);
});

/*===================================
=            slider init            =
===================================*/

// flickIt.initSlider($('.experts-slider'));


/*=================================
=            accordion            =
=================================*/

$(document).on('click', '.js_openAccordion', function() {
    var $this = $(this);
    var ww = window.innerWidth;
	if ($this.parent().hasClass('is-active')) {
		$('.js_openAccordion').parent().removeClass('is-active');
        $('.site').removeClass('is-active-acc');
        if (ww <= 1024) {
            $('body').removeClass('is-fixed');
        }

	} else {
		$('.js_openAccordion').parent().removeClass('is-active');
		$this.parent().addClass('is-active');
        $('.site').addClass('is-active-acc');
        if (ww > 1024) {
            $([document.documentElement, document.body]).animate({
                scrollTop: $this.offset().top
            }, 200);
        } else {
            $('body').addClass('is-fixed');
        }
	}

});


/*==================================
=            newsletter            =
==================================*/

/*======================================
    =            subscribe user            =
    ======================================*/

    function validateForm(form) {
        var formLength = form.length;
        // if (formLength >= 2) {
        //     for (var i = form.length - 1; i >= 0; i--) {
        //         if (form[i].name == 'email') {
        //             if (validateEmail(form[i].value)) {
        //                 return true;
        //             } else {
        //                 errorEmail();
        //             }
        //         }
        //     }
        // } else {
        // }
        for (var i = form.length - 1; i >= 0; i--) {
            if (form[i].name == 'email') {
                if (!validateEmail(form[i].value)) {
                    errorEmail();   
                }
            }
        }
        // errorDatenschutz();
        return false;
    }

    function errorDatenschutz() {
        // var errorTime = 4000;
        // $('form .input-wrapper-datenschutz').addClass('has_error');
        // setTimeout(function() {
        //     $('form .input-wrapper-datenschutz').removeClass('has_error');
        // }, errorTime);
    }

    function errorEmail(type) {
        // var errorclass = '';
        // if (type == 'exists') {
        //     errorclass = 'error-exists';
        // } else if(type == 'blocked') {
        //     errorclass = 'error-blocked';
        // } else {
        //     errorclass = '';
        // }

        // var errorTime = 4000;
        // $('form .input-wrapper-email').addClass('has_error').addClass(errorclass);
        // setTimeout(function() {
        //     $('form .input-wrapper-email').removeClass('has_error').removeClass('error-exists').removeClass('error-blocked');
        // }, errorTime);
    }

    function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }



    $(document).on('click', '.js_subscribeNl', function(e) {
        e.preventDefault();
        var $this = $(this);
        if (!$this.hasClass('is_locked')) {
            $this.addClass('is_locked');

            var $form = $(this).closest('form');
            var $input = $form.find('.input-text');
            // var $inputPlaceholder = $form.find('.input-placeholder');
            var $inputError = $form.find('.input-error');
            var $loader = $form.find('.svg-circle');
            var email = $input.val();
            var placeholderText = '';
            // console.log(email);
            // console.log(form);

            $loader.addClass('is-visible');

            if (validateEmail(email)) {
                $.ajax({
                    type: 'POST',
                    url: '/wp-admin/admin-ajax.php',
                    dataType: 'json',
                    data: {
                        action: 'addSubscriber',
                        email: email
                    },
                })
                .done(function(data) {
                    console.log("success");

                    var data = JSON.parse(data)
                    
                    console.log(data);
                    if (data.status == 'pending') {
                        console.log('pending');
                        $input.val('');
                        $form.html('');

                        placeholderText = $form.attr('data-thanks');

                        $form.addClass('is-done');
                        $form.prev().html(placeholderText)
                        
                        // $form.html('<div class="thanks-msg">' + placeholderText + '</div>');

                    } else {
                        $form.addClass('has-error');

                        if (data.title == "Member Exists") {
                            console.log('subscriber exists');
                            placeholderText = $form.attr('data-error-user-exists');
                        } else {
                            console.log('subscriber blocked');
                            placeholderText = $form.attr('data-error-global');
                        }

                        $inputError.html(placeholderText);
                        setTimeout(function() {
                            $form.removeClass('has-error');
                            $inputError.html('');
                        }, 3000);

                    }
                })
                .fail(function() {
                    console.log("error");
                })
                .always(function() {
                    $this.removeClass('is_locked');
                    $loader.removeClass('is-visible');
                    console.log("complete");
                });
            } else {
                $this.removeClass('is_locked');
                $loader.removeClass('is-visible');
                if (email == '') {
                    placeholderText = $form.attr('data-error-email-empty');
                    console.log('email empty');
                } else {
                    placeholderText = $form.attr('data-error-email-invalid');
                    console.log('email wrong');

                }
                
                $form.addClass('has-error');
                $inputError.html(placeholderText);
                
                setTimeout(function() {
                    // $form.css('border', '1px solid white');
                    $form.removeClass('has-error');
                    $inputError.html('');
                }, 3000);
            }
        
        }
    });


/*====================================
=            resize stuff            =
====================================*/


setTimeout(function() {
    $('html').removeClass('is_fading');
    $('svg').removeAttr('style');
    $('.nav').removeAttr('style');
}, 200);

setTimeout(function() {
    $('.loader').addClass('is_animated_up');
}, 800);

});
})(jQuery);