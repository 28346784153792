(function($) {
$(document).ready(function() {
    
    var module = {
    // ---------------------------------------------------------------
    // VALIDATE CHECKOUT FORM 1
    // ---------------------------------------------------------------

    validateForm: function(form) {

        // $('#email').tooltipster({
        //     trigger: 'custom', // default is 'hover' which is no good here
        //     onlyOne: false,    // allow multiple tips to be open at a time
        //     timer: 2500,
        //     position: 'right'  // display the tips to the right of the element
        // });
        // $.validator.addMethod("uidValid", function(value, element) {
        //   // allow any non-whitespace characters as the host part
        //       return this.optional( element ) || /^([A-z]{2,4})([0-9]{7})([0-9A-z]{0,6})$/.test( value );
        // }, 'Bitte geben Sie eine gültige UID Nummer an');

        // $.validator.addMethod("validPhone", function(value, element) {
        //   // allow any non-whitespace characters as the host part
        //       return this.optional( element ) || /^(?:\+\d{1,3}|0\d{1,3}|00\d{1,2})?(?:\s?\(\d+\))?(?:[-\/\s.]|\d)+$/.test( value );

        //       // if (preg_match(/[\-\+]?\s*[0-9]{1,3}(\.[0-9]{3})*,[0-9]+/, value)) {
        //       //   return true;
        //       // } else {
        //       //   return false;
        //       // }


        // }, 'Bitte geben Sie eine gültige Telefonnummer an');



        // $.validator.addMethod("specialSelect", function(value, element) {
        //     if (value == 'default') {
        //         return false;
        //     } else {
        //         return true;
        //     }

        // }, 'Pflichtfeld');


        $.extend($.validator.messages, {
            required: $('#' + form).attr('data-pflichtfeld'),
            email: $('#' + form).attr('data-wrong-email')
        });

        // $.validator.addMethod("uidInEu", function(value, element) {
        //     var euCountries = ['ÖSTERREICH','DEUTSCHLAND','BELGIUM','BULGARIA','CROATIA','CYPRUS','CZECH REPUBLIC','DENMARK','ESTONIA','FINLAND','FRANCE','GREECE','HUNGARY','IRELAND','ITALY','LATVIA','LITHUANIA','LUXEMBOURG','MALTA','MONACO','NETHERLANDS','POLAND','PORTUGAL','ROMANIA','SLOVAKIA','SLOVENIA','SPAIN','SWEDEN','UNITED KINGDOM'];

        //     var euCountriesPairValue = {AT: 'ÖSTERREICH', DE:'DEUTSCHLAND',BE:'BELGIUM',BG:'BULGARIA',HR:'CROATIA',CY:'CYPRUS',CZ:'CZECH REPUBLIC',DK:'DENMARK',EE:'ESTONIA',FI:'FINLAND',FR:'FRANCE',GR:'GREECE',HU:'HUNGARY',IE:'IRELAND',IT:'ITALY',LV:'LATVIA',LT:'LITHUANIA',LU:'LUXEMBOURG',MT:'MALTA',MC:'MONACO',NL:'NETHERLANDS',PL:'POLAND',PT:'PORTUGAL',RO:'ROMANIA',SK:'SLOVAKIA',SI:'SLOVENIA',ES:'SPAIN',SE:'SWEDEN',UK:'UNITED KINGDOM'};

        //     var $country = $('#country').val();
        //     var $country2 = $('#country2').val();
        //     if($country2 == '') {
        //       var $countrytest = 'true';
        //     } else if($country2 == $country) {
        //       var $countrytest = 'true';
        //     } else {
        //         $countrytest = 'false';
        //     }
        //     var code = value.substr(0, 2);

        //     console.log(euCountriesPairValue[code] == $country);

        //     return this.optional( element ) || (euCountries.indexOf($country) > -1 &&  euCountriesPairValue[code] == $country && $countrytest == 'true') || $('#checkout1__uid').val() == '';

        // }, 'Bitte geben Sie eine gültige UID Nummer an');

        $('#'+form+' input, #'+form+' textarea, #'+form+' select').tooltipster({
            trigger: 'custom', // default is 'hover' which is no good here --> custom
            onlyOne: false,    // allow multiple tips to be open at a time
            timer: 3000,
            position: 'left',  // display the tips to the right of the element
            // offsetY: 42,
            // fixedLineage: true,
            // repositionOnScroll: true,
            theme: 'custom-tooltipster'
        });
        $('#'+form).validate({
            rules: {
                // checkout1__uid: {
                //     uidValid: true,
                //     uidInEu: true
                // },
                // Phone: {
                //     validPhone:true
                // }
                // sex: {
                //     specialSelect:true
                // },
                // nationality: {
                //     specialSelect:true
                // },
                // country: {
                //     specialSelect:true
                // }
            },
            errorPlacement: function(error, element) {
            var ele = $(element),
                        err = $(error),
                        msg = err.text();
                    if (msg != null && msg !== '') {
                        ele.tooltipster('content', msg);
                        ele.tooltipster('open');
                    }
            },
            unhighlight: function(element, errorClass, validClass) {
                    $(element).removeClass(errorClass).addClass(validClass).tooltipster('close');
                },
            onfocusout: false,
            onkeyup: false
        });

    }
}
return module;
});
})(jQuery);

// /*================================
// =            VALIDATE            =
// ================================*/

//     function validateForm(form) {

//         // $('#email').tooltipster({
//         //     trigger: 'custom', // default is 'hover' which is no good here
//         //     onlyOne: false,    // allow multiple tips to be open at a time
//         //     timer: 2500,
//         //     position: 'right'  // display the tips to the right of the element
//         // });
//         // $.validator.addMethod("uidValid", function(value, element) {
//         //   // allow any non-whitespace characters as the host part
//         //       return this.optional( element ) || /^([A-z]{2,4})([0-9]{7})([0-9A-z]{0,6})$/.test( value );
//         // }, 'Bitte geben Sie eine gültige UID Nummer an');

//         // $.validator.addMethod("validPhone", function(value, element) {
//         //   // allow any non-whitespace characters as the host part
//         //       return this.optional( element ) || /^(?:\+\d{1,3}|0\d{1,3}|00\d{1,2})?(?:\s?\(\d+\))?(?:[-\/\s.]|\d)+$/.test( value );

//         //       // if (preg_match(/[\-\+]?\s*[0-9]{1,3}(\.[0-9]{3})*,[0-9]+/, value)) {
//         //       //   return true;
//         //       // } else {
//         //       //   return false;
//         //       // }


//         // }, 'Bitte geben Sie eine gültige Telefonnummer an');



//         // $.validator.addMethod("specialSelect", function(value, element) {
//         //     if (value == 'default') {
//         //         return false;
//         //     } else {
//         //         return true;
//         //     }

//         // }, 'Pflichtfeld');


//         $.extend($.validator.messages, {
//             // required: "Pflichtfeld",
//             // email: "Bitte fügen Sie eine gültige Emailadresse ein.",

//             required: $('#' + form).attr('data-pflichtfeld'),
//             email: $('#' + form).attr('data-wrong-email')
//         //     // checkout1__uid: "Bitte geben Sie eine gültige UID Nummer an."
//         });

//         // $.validator.addMethod("uidInEu", function(value, element) {
//         //     var euCountries = ['ÖSTERREICH','DEUTSCHLAND','BELGIUM','BULGARIA','CROATIA','CYPRUS','CZECH REPUBLIC','DENMARK','ESTONIA','FINLAND','FRANCE','GREECE','HUNGARY','IRELAND','ITALY','LATVIA','LITHUANIA','LUXEMBOURG','MALTA','MONACO','NETHERLANDS','POLAND','PORTUGAL','ROMANIA','SLOVAKIA','SLOVENIA','SPAIN','SWEDEN','UNITED KINGDOM'];

//         //     var euCountriesPairValue = {AT: 'ÖSTERREICH', DE:'DEUTSCHLAND',BE:'BELGIUM',BG:'BULGARIA',HR:'CROATIA',CY:'CYPRUS',CZ:'CZECH REPUBLIC',DK:'DENMARK',EE:'ESTONIA',FI:'FINLAND',FR:'FRANCE',GR:'GREECE',HU:'HUNGARY',IE:'IRELAND',IT:'ITALY',LV:'LATVIA',LT:'LITHUANIA',LU:'LUXEMBOURG',MT:'MALTA',MC:'MONACO',NL:'NETHERLANDS',PL:'POLAND',PT:'PORTUGAL',RO:'ROMANIA',SK:'SLOVAKIA',SI:'SLOVENIA',ES:'SPAIN',SE:'SWEDEN',UK:'UNITED KINGDOM'};

//         //     var $country = $('#country').val();
//         //     var $country2 = $('#country2').val();
//         //     if($country2 == '') {
//         //       var $countrytest = 'true';
//         //     } else if($country2 == $country) {
//         //       var $countrytest = 'true';
//         //     } else {
//         //         $countrytest = 'false';
//         //     }
//         //     var code = value.substr(0, 2);

//         //     console.log(euCountriesPairValue[code] == $country);

//         //     return this.optional( element ) || (euCountries.indexOf($country) > -1 &&  euCountriesPairValue[code] == $country && $countrytest == 'true') || $('#checkout1__uid').val() == '';

//         // }, 'Bitte geben Sie eine gültige UID Nummer an');

//         $('#'+form+' input, #'+form+' textarea, #'+form+' select').tooltipster({
//             trigger: 'custom', // default is 'hover' which is no good here --> custom
//             onlyOne: false,    // allow multiple tips to be open at a time
//             timer: 3000,
//             position: 'left',  // display the tips to the right of the element
//             // offsetY: 42,
//             // fixedLineage: true,
//             // repositionOnScroll: true,
//             theme: 'custom-tooltipster'
//         });
//         $('#'+form).validate({
//             rules: {
//                 email: {
//                     email: true
//                 },
//                 // Phone: {
//                 //     validPhone:true
//                 // }
//                 // sex: {
//                 //     specialSelect:true
//                 // },
//                 // nationality: {
//                 //     specialSelect:true
//                 // },
//                 // country: {
//                 //     specialSelect:true
//                 // }
//             },
//             errorPlacement: function(error, element) {
//             var ele = $(element),
//                         err = $(error),
//                         msg = err.text();
//                     if (msg != null && msg !== '') {
//                         ele.tooltipster('content', msg);
//                         ele.tooltipster('open');
//                     }
//             },
//             unhighlight: function(element, errorClass, validClass) {
//                     $(element).removeClass(errorClass).addClass(validClass).tooltipster('close');
//                 },
//             onfocusout: false,
//             onkeyup: false
//         });

//     }