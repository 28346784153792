
; $ = global.$ = require("jQuery");
require("flickity-imagesloaded");
require("js-throttle-debounce");
; var __browserify_shim_require__=require;(function browserifyShim(module, exports, require, define, browserify_shim__define__module__export__) {
// var $ = require('jQuery')
// var Flickity   = require('flickity');
var debounce   = __browserify_shim_require__('js-throttle-debounce');
var Flickity = __browserify_shim_require__('flickity-imagesloaded');

    var flickIt = {

        resizeModule: function() {
            if (!$('html').hasClass('isIos') && !$('html').hasClass('isAndroid')) {
                flickIt.initSliders("reload");
            }
        },
        // initSlider: function($slider, optionsDesk, optionsMobile) {

        //     console.log(optionsDesk);

        //     var standardOptionsDesk = {
        //         contain: true,
        //         setGallerySize: false,
        //         adaptiveHeight: false,
        //         groupCells: 1,
        //         wrapAround: false
        //     }
        //     var standardOptionsMobile = {
        //         contain: true,
        //         setGallerySize: false,
        //         adaptiveHeight: false,
        //         groupCells: 1,
        //         wrapAround: false
        //     }
        //     var ww = $(window).outerWidth();

        //     $slider.each(function(index, el) {
        //         var id = $(this).attr('id');
        //         if (ww <= 1024) {
        //             if (optionsMobile != undefined) {
        //                 var options = options.assign(standardOptionsstandardOptionsMobile, optionsMobile);
        //             } else {
        //                 options = standardOptionsMobile;
        //             }
        //         } else {
        //             if (optionsDesk != undefined) {
        //                 var options = options.assign(standardOptionsstandardOptionsDesk, optionsDesk);
        //             } else {
        //                 options = standardOptionsDesk;
        //             }

        //         }

        //         flickIt.initSliders(id, options);
        //     });

        // },
        initSliders: function(action) {
            if ($(".slider").length) {
                $(".slider").each(function() {
                    var id = $(this).attr("id");
                    var ww = $(window).outerWidth();
                    var actOpt = undefined;

                    if ($(this).hasClass('slider-experts')) {
                        
                        if (ww <= 768) {
                            var actOpt = {

                                contain: false,
                                percentPosition: true,
                                prevNextButtons: false,
                                // setGallerySize: false,
                                adaptiveHeight: false,
                                imagesLoaded: true,
                                cellAlign: "left",
                                // cellAlign: leftOffset / sliderWidth,
                                pageDots: false,
                                wrapAround: false,
                                groupCells: 1,
                                draggable: true,
                            };
                        } else {
                            var actOpt = {
                                contain: false,
                                percentPosition: true,
                                prevNextButtons: false,
                                // setGallerySize: false,
                                adaptiveHeight: false,
                                imagesLoaded: true,
                                cellAlign: "left",
                                // cellAlign: leftOffset / sliderWidth,
                                pageDots: false,
                                wrapAround: false,
                                groupCells: 2,
                                draggable: false,

                            };
                        }
                    } else if($(this).hasClass('slider-logos')) {
                        if (ww <= 768) {
                            var actOpt = {

                                contain: false,
                                percentPosition: true,
                                prevNextButtons: false,
                                // setGallerySize: false,
                                adaptiveHeight: false,
                                cellAlign: "left",
                                // cellAlign: leftOffset / sliderWidth,
                                pageDots: false,
                                wrapAround: true,
                                groupCells: 1,
                                draggable: true,
                                cellSelector: '.logo-item',
                            };
                        } else {
                            var actOpt = {
                                contain: true,
                                percentPosition: true,
                                prevNextButtons: false,
                                // setGallerySize: false,
                                adaptiveHeight: false,
                                cellAlign: "left",
                                // cellAlign: leftOffset / sliderWidth,
                                pageDots: false,
                                wrapAround: true,
                                groupCells: 1,
                                draggable: true,
                                cellSelector: '.logo-item',
                            };
                        }
                    } else {

                       if (ww <= 768) {
                            var actOpt = {

                                contain: false,
                                percentPosition: true,
                                prevNextButtons: false,
                                // setGallerySize: false,
                                adaptiveHeight: false,
                                cellAlign: "left",
                                // cellAlign: leftOffset / sliderWidth,
                                pageDots: false,
                                wrapAround: false,
                                groupCells: 1,
                                draggable: true,
                            };
                        } else {
                            var actOpt = {
                                contain: false,
                                percentPosition: true,
                                prevNextButtons: false,
                                // setGallerySize: false,
                                adaptiveHeight: false,
                                cellAlign: "left",
                                // cellAlign: leftOffset / sliderWidth,
                                pageDots: false,
                                wrapAround: false,
                                groupCells: 1,
                                draggable: false,

                            };
                        }
                    }


                    if (action == "reload") {

                        var elem = document.getElementById(id);
                        var flkty = Flickity.data(elem);

                        flkty.destroy();
                    }

                    flickIt.initSlider(id, actOpt);
                });
            }
        },
        initSlider: function(e, o) {
            // var elem = document.querySelector(e);
            var elem = document.getElementById(e);
            var options = {};
            if (o != undefined) {
                options = o;
            } else {
                options = {
                    cellAlign: "left",
                    contain: true,
                    // accessibility: false,
                    percentPosition: true,
                    autoPlay: 5000,
                    // freeScroll: true,
                    prevNextButtons: false,
                    setGallerySize: true,
                    // adaptiveHeight: true,
                    pageDots: false,
                    wrapAround: true
                };
            }

            setTimeout(function() {
                var flkty = new Flickity(elem, options);
                var event = "click";
                var www = $(window).outerWidth();


                // var $prevB = $(elem).parent().next().find('.button-prev');
                // var $nextB = $(elem).parent().next().find('.button-next');


                if ($(elem).find(".slider-buttons").length > 0) {
                    var $prevB = $(elem)
                        .find(".slider-buttons")
                        .find(".slider-button-prev");

                    var $nextB = $(elem)
                        .find(".slider-buttons")
                        .find(".slider-button-next");

                } else {
                    var $prevB = $(elem)
                        .closest(".section")
                        .find(".slider-button-prev");

                    var $nextB = $(elem)
                        .closest(".section")
                        .find(".slider-button-next");                    
                }


                if (options.draggable !== false) {
                    elem.ontouchmove = function (e) { // prevent mobile scroll out of viewport (whobble)
                        e.preventDefault();
                    }
                    document.ontouchmove = function (e) { // Enable scrolling.
                        return true; // Not use e.preventDefault()
                    }


                    // prevent click when items are links on drag
                    $(elem).on('dragStart.flickity', () => $(elem).css('pointer-events', 'none'));
                    $(elem).on('dragEnd.flickity', () => $(elem).css('pointer-events', 'all'));
                }

                var buttons = [$prevB, $nextB];

                $.each(buttons, function(index, el) {
                    $(el).unbind(event);
                    $(el).on(event, function(evt) {
                        evt.stopPropagation();
                        evt.preventDefault();
                        // console.log(event);

                        // console.log(flkty.slides.length, 'length:');
                        // console.log(index, 'index:');

                        console.log('button_click');

                        if ($(this).hasClass("slider-button-next")) {
                            flkty.next(false, false);
                        } else {
                            flkty.previous(false, false);
                        }

                    });
                });
                
                var $slider = $(elem);
                var $section = $slider.closest('.section');
                var $sliderButtons = $section.find('.slider-buttons');
                if ($section.find('.expert-item:not(.expert-item .expert-item)').length) {
                    var sliderLength = $section.find('.expert-item:not(.expert-item .expert-item)').length;
                } else {
                    var sliderLength = flkty.slides.length;
                }

                // console.log($section.find('.expert-item:not(.expert-item .expert-item)').length, 'sliderLength');

                // if (www > 768) {
                //     if (flkty.slides.length <= 1) {
                //         $sliderButtons.find('.slider-button-prev').addClass('is-hidden');
                //         $sliderButtons.find('.slider-button-next').addClass('is-hidden');
                //     }
                // } else {
                // }
                    // if (flkty.slides.length <= 2) {
                    if (sliderLength <= 2) {
                        $sliderButtons.find('.slider-button-prev').addClass('is-hidden');
                        $sliderButtons.find('.slider-button-next').addClass('is-hidden');
                    }


                flkty.on( 'change', function( index ) {


                    var $slider = $(elem);
                    var $section = $slider.closest('.section');
                    var $sliderButtons = $section.find('.slider-buttons');


                    setTimeout(function() {

                        var ww = $(window).outerWidth();
                        // $(elem).hasClass('slider-insights')
                        if (ww > 768) {
                            $sliderButtons.find('.slider-button-prev').removeClass('is-hidden');
                            $sliderButtons.find('.slider-button-next').removeClass('is-hidden');

                            if (index == 0) {
                                console.log('first');
                                $sliderButtons.find('.slider-button-prev').addClass('is-hidden');
                            } else if(index+1 == flkty.slides.length) {
                                console.log('last');
                                $sliderButtons.find('.slider-button-next').addClass('is-hidden');
                            } else {
                                console.log('between');
                            }

                            // if (flkty.slides.length <= 1) {
                            //     $sliderButtons.find('.slider-button-prev').addClass('is-hidden');
                            //     $sliderButtons.find('.slider-button-next').addClass('is-hidden');
                            // }
                        } else {
                            if (flkty.slides.length <= 2) {
                                $sliderButtons.find('.slider-button-prev').addClass('is-hidden');
                                $sliderButtons.find('.slider-button-next').addClass('is-hidden');
                            }
                        }
                        
                    }, 10);

                });

            }, 200);
        },

    }

    window.onresize = flickIt.resizeModule.debounce(250);

    flickIt.initSliders("init");

    // return flickIt;
    flickIt.exports = flickIt;
; browserify_shim__define__module__export__(typeof flickIt != "undefined" ? flickIt : window.flickIt);

}).call(global, undefined, undefined, undefined, undefined, function defineExport(ex) { module.exports = ex; });
