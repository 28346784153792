// var $ = require('jQuery');
// var googlemaps = require('./Vendor/googlemaps.js');
// var GMaps = require('./Vendor/gmaps.js');

var mapIt = {

    map: false,
    markers: {},

    initGmaps: function(action) {
        if ($('#gmaps').length != 0) {

            // if ('pad-' in mbiMq.mqArea) {
            //     var lat = 50.438070;
            //     var lng = 7.398080;
            //     var drag = false;
            //     var scrolli = false;
            // } else {
                var lat = 48.196690;
                var lng = 16.342660;
                var drag = true;
                var scrolli = false;
            // }

            // var zoom = 14;
            // if ('paw-' in mbiMq.mqArea) {
                zoom = 15;
            // }

            mapIt.map = new GMaps({
                div: '#gmaps',
                lat: lat,
                lng: lng,
                zoom: zoom,
                draggable: drag,
                // refresh:true,
                scrollwheel:scrolli,
                styles: [
  {
    "stylers": [
      {
        "saturation": -80
      },
      {
        "lightness": 30
      }
    ]
  },
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#bf1212"
      },
      {
        "saturation": -80
      }
    ]
  },
  {
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#212121"
      }
    ]
  },
  {
    "elementType": "labels",
    "stylers": [
      {
        "color": "#3d433a"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "color": "#3d433a"
      },
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#212121"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#181818"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#3d433a"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1b1b1b"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#2c2c2c"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#8a8a8a"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#373737"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#373737"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#373737"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#3c3c3c"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#3d433a"
      }
    ]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#4e4e4e"
      }
    ]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#3c3c3c"
      }
    ]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#3c3c3c"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#3c3c3c"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#3c3c3c"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#000000"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#3d433a"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#3d3d3d"
      }
    ]
  }
]

                // featuretype list: https://developers.google.com/maps/documentation/javascript/style-reference#style-features
                // https://mapstyle.withgoogle.com/
            });

            var $urlPrefix = $('html').attr('data-assets-url');

            var image = {
                url: $urlPrefix + '/Svg/gmaps-button.png'
            }
            console.log($urlPrefix +'/Svg/gmaps-button.png');
            var marker = {
                lat : 48.196690,
                lng : 16.342660,
                icon:image,
                click: function(e) {
                    window.open('https://www.google.at/maps/place/Wehrbr%C3%BCcklstra%C3%9Fe+6,+1220+Wien/@48.2171665,16.526297,17z/data=!3m1!4b1!4m5!3m4!1s0x476d01c81c6295bf:0xc482464271933bf6!8m2!3d48.2171629!4d16.5284857');
                }
//

            }
            console.log(mapIt.map);
            mapIt.map.addMarker(marker);


        }
    }
}

        
// mapIt.initGmaps();
// mapIt.exports = mapIt;